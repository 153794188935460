import React from "react";
import {
  FcDataProtection,
  FcLock,
  FcApproval,
  FcHeadset,
  FcKey,
  FcDataBackup,
  FcGlobe,
} from "react-icons/fc";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import SydneyGraphic from "../images/security/SydneyGraphic.svg";

const Security = (props) => (
  <Layout>
    <Seo
      title="Modern online security you can trust | FlexiTime"
      description="With FlexiTime's secure, cloud-based products, you can take care of your team with peace of mind."
      pathname={props.location.pathname}
    />
    <Container>
      <Wrapper stackGap="0">
        <Row stackGap={70} alignCenter>
          <Box
            css={{ padding: "40px 0" }}
            stackGap={15}
            size={60}
            centerOnMobile
          >
            <h1 className="-fontLarge">
              <FcLock />
              <br />
              Modern online security{" "}
              <span className="-fontPrimary">you can trust</span>
            </h1>
            <h2 className="-fontNormal" css={{ fontSize: "2rem" }}>
              With FlexiTime's secure, cloud-based products, you can take care
              of your team with peace of mind. That's because we protect your
              business and your employees' sensitive data with top-notch online
              security.
            </h2>
          </Box>
          <Box size={40}>
            <Image filename="TrustHeaderSQ.png" alt="Security at FlexiTime" />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <Container bg="lightGrey">
      <Wrapper stackGap={120} maxWidth={800} className="font-bump -textCenter">
        <div>
          <h2>
            <FcDataProtection />
            <br />
            Built for data security
          </h2>
          <h4>
            We host our infrastructure on the Microsoft Azure Cloud Platform. We
            use Azure Advanced Data Security with Transparent Data Encryption on
            SQL Server instances to provide data encryption at rest. Your data
            is encrypted with industry leading cryptographic algorithms. All of
            our encryption protocols and algorithms follow the guidelines
            outlined in the New Zealand Information Security Manual (NZISM).
          </h4>
        </div>
        <div>
          <h2>
            <FcApproval />
            <br />
            Security compliance and ongoing improvements
          </h2>
          <h4>
            We are committed to providing a secure environment for our products
            that adhere to international security standards. Azure performs
            automated scans on our cloud infrastructure to ensure the correct
            controls are in place to meet the requirements for security
            standards such as ISO 27001 and PCI DSS 3.2.1. For any questions
            about our compliance with international standards please contact{" "}
            <a href="mailto:security@flexitime.works">
              security@flexitime.works
            </a>
            .
          </h4>
        </div>
        <div>
          <h2>
            <FcKey />
            <br />
            Additional security layer on sign in
          </h2>
          <h4>
            Add an additional layer of security to your account with two-factor
            authentication for improved protection of sensitive data and payroll
            information. Each time you log in, you’ll need to enter a unique
            code generated by an app on your smartphone. Used by many internet
            banking and online services, two-step authentication makes it much
            harder for anyone to hack into your account.
          </h4>
        </div>
        <div>
          <h2>
            <FcDataBackup />
            <br />
            Reliable and accessible
          </h2>
          <h4>
            Rest assured knowing that your data is backed up multiple times per
            day. We have a robust in-house monitoring & response system to
            immediately identify potential issues and threats; our
            high-availability systems provide greater than 99.9% uptime.
          </h4>
        </div>
        <div>
          <h2>
            <FcHeadset />
            <br />
            Trained support you can count on
          </h2>
          <h4>
            Our support team have training in data security & privacy. Access to
            your account & data is controlled by you to ensure no exposure of
            any personal data. With FlexiTime, you can be sure your information
            is secure and will stay that way.
          </h4>
        </div>
      </Wrapper>
    </Container>
    <Container bg="emerald" className="-dark -whiteOut" graphicalBR>
      <Wrapper stackGap={120} className="font-bump">
        <Row stackGap={50} alignCenter>
          <Box size={50}>
            <h2>
              <FcGlobe />
              <br />
              Advanced database hosting
            </h2>
            <h4>
              The primary database is in Sydney with a mirrored database hosted
              in Adelaide. SQL Database uses SQL Server technology to create
              full backups every week, differential backups every 12 hours, and
              transaction log backups every 5-10 minutes. This keeps your data
              safe, even in the event of a disaster.
            </h4>
          </Box>
          <Box size={50}>
            <img
              className="-center"
              css={{
                width: "100%",
                maxWidth: "400px",
              }}
              src={SydneyGraphic}
              alt="Server Based in Sydney"
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
  </Layout>
);

export default Security;
